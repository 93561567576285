import React from 'react';
import Cta from '../components/Cta';
import Feature2 from '../components/Feature2';
import Precio from '../components/Precio';
import HowItWorks from '../components/HowItWorks';
import TextArea from '../components/TextArea';
import Input from '../components/Input';
import InputEmail from '../components/InputEmail';
import Contacto from '../components/Contacto';
import SuccessModal from '../components/SuccessModal';
import FormaRegistroUsuario from '../components/registroyacceso/FormaRegistroUsuario';

function AltaCliente_CapturaNombreEmail() {
  return (
    <div> 
         <FormaRegistroUsuario />
    </div>
  );
}

export default AltaCliente_CapturaNombreEmail;
