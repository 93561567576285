import React,{ useEffect, useState } from 'react';

import imagen1 from './images/plomero.jpg';
import imagen2 from './images/albanil.jpg';
import imagen3 from './images/electricista.jpg';
import imagen4 from './images/limpieza.jpg';
import imagen5 from './images/fumigador.jpg';
import imagen6 from './images/jardinero.jpg';
import imagen7 from './images/cerrajero.jpg';
import imagen8 from './images/moving.jpg';


import { useNavigate } from 'react-router-dom';
import { Carousel } from "flowbite-react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
//import tecnicos from './tecnicosToDelete.js';
import areas from './area.js';
import { Card } from "flowbite-react";

function Cta(){
    const [num, setNum] = useState(1);

    const navigate = useNavigate();

    const handleClick = () => {
        console.log('empezando...');
        navigate('/teEscuchamos'); // Change to the desired route
    };

    const randomNumberInRange = (min, max) => {
        return Math.floor(Math.random()
            * (max - min + 1)) + min;
    };

    useEffect(() => {
        // Code to execute only once on mount
        console.log('Component mounted!');
        setNum(randomNumberInRange(1, 4));


      }, []); // Empty dependency array ensures this runs only once

      

      const [value, setValue] = React.useState();

      const handleChange1 = (event, newValue) => {
        setValue(newValue);
      };
/*

    <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
      <Carousel>
          
        <img src={imagen1} alt="..." />
        
        <img src={imagen2} alt="..." />
        <img src={imagen3} alt="..." />
      </Carousel>

      
    </div>

*/
   
// <form action="#" method="POST" className="mt-8 lg:mt-12">

//              <img className="object-cover w-full h-full md:object-left md:scale-150 md:origin-top-left" src={imagen1} alt="" />

/*

            <Card  className="max-w">

<Autocomplete
          value={value}
          onChange={handleChange1}
          disablePortal
          options={tecnicos}
          sx={{ width: 350 }}
          renderInput={(params) => <TextField {...params} label="Tipo" />}
      />
<br></br>
<Autocomplete
          value={value}
          onChange={handleChange1}
          disablePortal
          options={areas}
          sx={{ width: 350 }}
          renderInput={(params) => <TextField {...params} label="Area" />}
      />


</Card>


      <a
                    href="#"
                    title=""
                    class="inline-flex items-center justify-center px-8 py-3 text-base font-bold leading-7 text-gray-900 transition-all duration-200 bg-white border border-transparent rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                    role="button"
                >
                    Explorar
                </a>
*/
    return (
     
        <section class="relative py-8 bg-gray-900 sm:py-12 lg:py-20 xl:py-32">
    <div class="absolute inset-0">
        

        {
        /*(() => {
        switch (num) {
          case 1:
            return  <img class="object-cover w-full h-full" src={imagen1} alt="" />
          case 2:
            return <img class="object-cover w-full h-full" src={imagen2} alt="" />
          case 3:
            return  <img class="object-cover w-full h-full" src={imagen3} alt="" />
          case 4:
                return  <img class="object-cover w-full h-full" src={imagen4} alt="" />
        }
      })()
    */
    }

    <Carousel>
        <img src={imagen1} alt="..." />
        <img src={imagen2}  alt="..." />
        <img src={imagen3}  alt="..." />
        <img src={imagen4}  alt="..." />
        <img src={imagen5}  alt="..." />
        <img src={imagen6}  alt="..." />
        <img src={imagen7}  alt="..." />
        <img src={imagen8}  alt="..." />
      </Carousel>

    </div>

    <div class="absolute inset-0 bg-gray-900/35"></div>

    <div class="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="max-w-xl mx-auto text-center">
            <h2 class="text-3xl font-bold text-white sm:text-4xl lg:text-5xl">Conecta con alguien de confianza en tu ciudad</h2>
            <p class="mt-4 text-base font-normal leading-7 text-white sm:mt-6">Plomeros,albañiles,electricistas,limpieza y más</p>

            <div class="mt-8 sm:mt-10">


<br></br>

          
            </div>
        </div>
    </div>



</section>



   


 
       
    );
}

export default Cta;