import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AlertRed from '../AlertRed';
import UserContext from '../User';
import { useContext } from 'react';
// const user = useContext(UserContext);
import { Alert } from "flowbite-react";
import { Spinner } from "flowbite-react";
//import SuccessMessage from '../components/SuccessMessage';

import { Button, Modal } from "flowbite-react";
import { HiCheckCircle } from "react-icons/hi";


const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

/*

   {!loading ?  <div> <Alert color="success" >
                  <span className="font-medium">Excelente!</span> Tu email y celular han sido verificados. Y has sido registrado exitosamente.
               </Alert></div> : <div></div>
               
              }

*/

const FormaValidaNumCelular = (props) => {   
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [codigoEmail, setCodigoEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [timerId, setTimerId] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  function startLoading() {
    setLoading(true);
    const id = setInterval(() => {
        if (loading >= 10) {
            clearInterval(id);
            stopLoading();
        }
    }, 500)
    setTimerId(id);
  }

  function stopLoading() {
      setLoading(false);
  }

  function loadModal() {
    setOpenModal(false);
    navigate('/home'); 
  }

  const handleClick = async (event) => {
    event.preventDefault();
    console.log('verifica codigo...');
    console.log('codigoEmail='+codigoEmail);
    console.log('codigo enviado a su email...'+user.codigoverificacion);
    if (codigoEmail==user.codigoverificacion){
      console.log('verificacion de codigo exitosa...');
    
      setError('');


      startLoading();
      setIsActive(true);

      try{
       //en produccion    .post('/api/rcusuario',
       const response = await axios
       .post('/api/rcusuario',
       {
           headers: {
               'Content-Type': 'application/json',
           },
                 "nombreCompleto": user.nombre,
           "email": user.email,
           "numCelular": user.celular,
           "tipoUsuario": 1,
           "passwordHash": user.clave,
           "createOn": "2025-03-01T05:40:34.000+00:00",
           "lastUpdate": "2025-03-01T05:40:36.000+00:00",
           "loggedIn": false,
           "visitDate": "2025-03-01T05:41:32.000+00:00"

       })
       .then(response => {
          console.log(response);
          console.log(response.data);
          //setData(response.data);
          if (response.status === 409) {
            //Option 1: Display an error message to the user
            console.error('Conflict: Resource already exists or has conflicting updates.');
            setError('Conflict: Resource already exists or has conflicting updates..');
            //Option 2: Attempt to resolve the conflict
            //Option 3: Refresh data
          }

          //startLoading();
          // if (!loading)     
          // setOpenModal(true);

        })
      } catch (err) {
          console.log('Error='+err);
          console.log('Status code='+err.status);

          if (err.status === 409) {
            //Option 1: Display an error message to the user
            console.error('Conflict: Resource already exists or has conflicting updates.');
            setError('Email proporcionado al principio ya esta registrado.');
            //Option 2: Attempt to resolve the conflict
            //Option 3: Refresh data
          }else{
            setError(err.message || 'An error occurred');
          }

          
      }
      finally {
        setLoading(false);
      }


  }


      
  



      //console.log('verificacion de codigo fallo...');
    
    
  };

  const handleCodigoEmailChange = (event) => {
    setCodigoEmail(event.target.value);
    //setFirstNameError(validateName(event.target.value) ? '' : 'Invalid first name');
  };


  
    return (
      <div>
          <section className="relative overflow-hidden bg-white">
      <div className="absolute inset-0">
        <div className='absolute inset-y-0 left-0 w-1/2 bg-yellow-300'></div>
      </div>

      <div className="absolute top-0 left-0 -translate-x-[60%] -translate-y-[75%] z-10">
        <div className="border-[8px] border-white rounded-full w-80 h-80 opacity-20"></div>
      </div>

      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div
          className="relative self-stretch px-4 py-6 overflow-hidde bg-yellow-300 sm:px-6 lg:col-span-2 lg:px-8 lg:py-12">
          <div className="absolute bottom-0 right-0 translate-x-[25%] translate-y-[75%]">
            <div className="border-[8px] border-white rounded-full w-96 h-96 opacity-20 lg:opacity-100"></div>
          </div>

          <div className="relative flex flex-col justify-between h-full max-w-lg mx-auto lg:mr-auto lg:max-w-md">
            <div className="flex-1">
              <h2 className="text-2xl font-semibold tracking-tighter text-white sm:text-5xl xl:text-6xl">
                

              </h2>
              <p className="mt-4 text-base font-normal leading-7 text-white lg:text-lg lg:mt-6 lg:leading-8">
            

              </p>
            </div>
          </div>
    
        </div>

        <div className="px-4 py-3 bg-white sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto xl:max-w-xl">
            <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Verificación de Celular
            </h2>
            <p className="mt-4 text-base font-normal leading-7 text-gray-600 lg:text-lg lg:mt-6 lg:leading-8">
            Se le acaba de mandar un mensaje de Whatsup con un código de verificación
            </p>

         


            <form action="#" method="POST" className="mt-12 space-y-12 sm:mt-16 lg:mt-20">
              <div className="space-y-4">


              <div className="text-left">
                        <label for="" className="text-base font-bold text-gray-900">Escriba el Código de verificación aqui:</label>
                            <div className="mt-2.5 relative">
                                <input autoFocus name="fullName" id="nombreid" value={codigoEmail}  onChange={handleCodigoEmailChange} required className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600" />
                            </div>
              </div>


              </div>
         
              {loading ?  <div className="text-center"><Spinner color="purple" aria-label="Extra large spinner example" size="xl" /></div> : <div></div>}
               
            
              

              {error && <AlertRed message={error} />}
              <button   onClick={handleClick} type="submit" style={{ 
                            backgroundColor: '#fb8500', 
                            color: 'white'
                          }}
    
                className="inline-flex items-center justify-center px-12 py-4 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700">
                Verificar celular
              </button>
            </form>

   
            <Modal show={openModal} size="md" onClose={() => loadModal() } popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiCheckCircle className="mx-auto mb-4 h-14 w-14 text-green-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Tu registro ha sido realizado.
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="success" onClick={() => loadModal()
              
              }>
                {"Continuar"}
              </Button>
              {/* <Button color="gray" onClick={() => setOpenModal(false)}>
                No, cancel
                        </Button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>




          </div>
        </div>
      </div>
    </section>
      </div>
    )
}
export default FormaValidaNumCelular;