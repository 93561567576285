import React from 'react';
import { Dropdown } from "flowbite-react";

const MenuGeneral = (props) => {
  return (
    <Dropdown label="Login" inline>
        <Dropdown.Header>
            <span className="block text-left text-lg m-1 w-64">Pacientes</span>
                <a href="/login" title="">
                    <Dropdown.Item> Login   </Dropdown.Item>
               </a>
        </Dropdown.Header>
   

      <Dropdown.Header>
            <span className="block text-left text-lg m-1">Terapistas</span>
            <Dropdown.Item> Login   </Dropdown.Item>
        </Dropdown.Header>
     


    </Dropdown>
  );
}

export default MenuGeneral;