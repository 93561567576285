import React, { useState, useEffect } from 'react';
import { io } from "socket.io-client";
import ChatRow from '../components/ChatRow';
//import io from 'socket.io-client'; // Add this

///const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://74.208.131.192:4000';

//const socket = io.connect(URL,{ path: 'socket.io'}, {query: "chatRoomId=123&userId=983912" });





//prod const socket = io.connect('', {  path: "/chat/", query: "chatRoomId=123&userId=983912" });
const socket = io.connect('http://localhost:8080', {  path: "/chat/", query: "chatRoomId=123&userId=983912" });

//const socket = io('http://localhost:3001');

function ChatRoomProveedor() {
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [user, setUser] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [mandamensaje, setMandamensaje] = useState('');

  


  // Send a message
  const sendMessageChat = () => {
    socket.emit('sendMessage', 47, messageText);
    setMessageText('');
  };

  


  // Receive messages
  useEffect(() => {
    socket.on('receiveMessage', (messageR) => {
      setMessages(messages => [...messages, messageR]);
    });
  }, []);


    // Join a chat room
  const joinChatRoom = (userDetails) => {
     setUser(userDetails);
     socket.emit('join', userDetails);
   };
  
   // Leave a chat room
   const leaveChatRoom = () => {
     socket.emit('leave', user);
     setUser(null);
  };
  // More code will go here


  


  return (
    <div class="py-12 bg-white sm:py-12 lg:py-20">
          <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            {messages.map((msg, index) => (
              < ChatRow mensaje={msg} />
        
            ))}


     

{/*
      
          <div className="chat-room">
              <input 
              className="block w-full px-4 py-4 text-base text-gray-900 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
              value={messageText} onChange={(e) => setMessageText(e.target.value)} />

              <br></br>
              <button onClick={sendMessageChat} style={{ 
                            backgroundColor: '#fb8500', 
                            color: 'white'
                          }}
    
                           className="inline-flex items-center justify-center px-12 py-4 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700">
                Mandar mensaje
              </button>
          </div>

                        */}



<div class="fixed bottom-14 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
    <label for="chat" class="sr-only">Your message</label>
    <div class="flex items-center px-3 py-2 rounded-lg bg-gray-50 dark:bg-gray-700">

        <textarea onChange={(e) => setMessageText(e.target.value)} id="chat" rows="2" class="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tu mensaje...">
          {messageText}
        </textarea>

            <button  onClick={sendMessageChat} type="submit" class="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600">
            <svg class="w-5 h-5 rotate-90 rtl:-rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#fb8500" viewBox="0 0 18 20">
                <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"/>
            </svg>
            <span class="sr-only">Send message</span>
        </button>
    </div>
  </div>

















         </div>               
  </div>
  );
}

export default ChatRoomProveedor;
