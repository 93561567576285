import React,{ useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Carousel } from "flowbite-react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
//import tecnicos from './tecnicosToDelete.js';
import areas from './area.js';
import { Card } from "flowbite-react";

function MenuAbajoCliente2(){
    const [num, setNum] = useState(1);

    const navigate = useNavigate();

    const handleClickPefil = () => {
        console.log('Perfil usuario..');
        navigate('/pperfil'); // Change to the desired route
    };

    const handleClickChat = () => {
        console.log('Perfil de chat...');
        navigate('/chat'); // Change to the desired route
    };
    
    const handleClickFavoritos = () => {
        console.log('Perfil de Favoritos...');
        navigate('/favoritos'); // Change to the desired route
    };

    const handleClickHome = () => {
        console.log('Perfil de Home...');
        navigate('/home'); // Change to the desired route
    };




    const randomNumberInRange = (min, max) => {
        return Math.floor(Math.random()
            * (max - min + 1)) + min;
    };

    useEffect(() => {
        // Code to execute only once on mount
        console.log('Component mounted!');
        setNum(randomNumberInRange(1, 4));


      }, []); // Empty dependency array ensures this runs only once

      

      const [value, setValue] = React.useState();

      const handleChange1 = (event, newValue) => {
        setValue(newValue);
      };
//#fb8500
    return (
     
   




<div class="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
    <div class="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
        <button  onClick={handleClickHome} type="button" class="inline-flex flex-col items-center justify-center px-5 border-gray-200 border-x hover:bg-gray-50 dark:hover:bg-gray-800 group dark:border-gray-600">
   

        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z"/></svg>
<span class="text-sm text-gray-500 dark:text-gray-400 group-hover:text-orange-600 dark:group-hover:text-blue-500">Home</span>
        </button>


        <button onClick={handleClickFavoritos} type="button" class="inline-flex flex-col items-center justify-center px-5 border-e border-gray-200 hover:bg-gray-50 dark:hover:bg-gray-800 group dark:border-gray-600">
      
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"/></svg>
            <span class="text-sm text-gray-500 dark:text-gray-400 group-hover:text-orange-600 dark:group-hover:text-blue-500">Favoritos</span>
        </button>
      


        <button onClick={handleClickChat} type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
 
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M880-80 720-240H160q-33 0-56.5-23.5T80-320v-480q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v720ZM160-320h594l46 45v-525H160v480Zm0 0v-480 480Z"/></svg>            
<span class="text-sm text-gray-500 dark:text-gray-400 group-hover:text-orange-600 dark:group-hover:text-blue-500">Mensajes</span>
        </button>

        
        <button onClick={handleClickPefil} type="button" class="inline-flex flex-col items-center justify-center px-5 border-gray-200 hover:bg-gray-50 dark:hover:bg-gray-800 group border-x dark:border-gray-600">
       
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-360q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-60Zm0 360Z"/></svg>
            <span class="text-sm text-gray-500 dark:text-gray-400 group-hover:text-orange-600 dark:group-hover:text-blue-500">Perfil</span>
        </button>
    </div>
</div>




   


 
       
    );
}

export default MenuAbajoCliente2;