import React, { useState } from 'react';

const PageTitle = (props) => {   




return (
<div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
    <p class="text-left max-w-lg text-3xl font-semibold leading-normal text-gray-900 dark:text-white">{props.title}</p>
</div>
);


}
export default PageTitle;
