import React,{ useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from "flowbite-react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import areas from './area.js';
import { Card } from "flowbite-react";

/*
  <div class="sm:flex sm:items-center sm:justify-between">
                <div>
                    <p class="text-lg font-bold text-gray-900">Contratistas Encontrados</p>
                    <p class="mt-1 text-sm font-medium text-gray-500">Lorem ipsum dolor sit amet, consectetur adipis.</p>
                </div>

                <div class="mt-4 sm:mt-0">
                    <label for="" class="sr-only"> Busca </label>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </div>

                        <input type="search" name="" id="" class="block w-full py-2 pl-10 border border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm" placeholder="Search App" />
                    </div>
                </div>
            </div>
*/
function Contratistas(){

    const navigate = useNavigate();

    const handleClick = () => {
      console.log('lista de contratistas...');
      navigate('/perfil'); // Change to the desired route
  };



    return (

 
<div >


    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-4xl mx-auto">

            <div class="flow-root mt-8">
                <div class="-my-5 divide-y divide-gray-200">
                    <div class="py-5">
                        <div class="sm:flex sm:items-center sm:justify-between sm:space-x-5">
                            <div class="flex items-center flex-1 min-w-0">
                                <img class="flex-shrink-0 object-cover w-12 h-12 rounded-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/4/avatar.jpg" alt="" />
                                <div class="flex-1 min-w-0 ml-4">
                                    <p class="text-sm font-bold text-gray-900 truncate">Plomero Juan Perez</p>
                                    <p class="mt-1 text-sm font-medium text-gray-500 truncate">Lorem ipsum dolor sit amet, consectetur adipis.</p>
                                </div>
                            </div>

                            <div class="flex items-center justify-between mt-4 sm:space-x-6 pl-14 sm:pl-0 sm:justify-end sm:mt-0">
                                <a       onClick={handleClick} href="#" title="" class="text-sm font-medium text-gray-400 transition-all duration-200 hover:text-gray-900"> Ver Mas </a>

                      
                            </div>
                        </div>
                    </div>

                    <div class="py-5">
                        <div class="sm:flex sm:items-center sm:justify-between sm:space-x-5">
                            <div class="flex items-center flex-1 min-w-0">
                                <img class="flex-shrink-0 object-cover w-12 h-12 rounded-full" src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/table-stacked/4/avatar-female.png" alt="" />
                                <div class="flex-1 min-w-0 ml-4">
                                    <p class="text-sm font-bold text-gray-900 truncate">Zapier</p>
                                    <p class="mt-1 text-sm font-medium text-gray-500 truncate">Lorem ipsum dolor sit amet, consectes.</p>
                                </div>
                            </div>

                            <div class="flex items-center justify-between mt-4 sm:space-x-6 pl-14 sm:pl-0 sm:justify-end sm:mt-0">
                                <a href="#" title="" class="text-sm font-medium text-gray-400 transition-all duration-200 hover:text-gray-900"> Ver Mas </a>

                        
                            </div>
                        </div>
                    </div>

                    <div class="py-5">
                        <div class="sm:flex sm:items-center sm:justify-between sm:space-x-5">
                            <div class="flex items-center flex-1 min-w-0">
                                <img class="flex-shrink-0 object-cover w-12 h-12 rounded-full" src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/table-stacked/4/avatar-male.png" alt="" />
                                <div class="flex-1 min-w-0 ml-4">
                                    <p class="text-sm font-bold text-gray-900 truncate">Telegram</p>
                                    <p class="mt-1 text-sm font-medium text-gray-500 truncate">Lorem ipsum dolor sit amet.</p>
                                </div>
                            </div>

                            <div class="flex items-center justify-between mt-4 sm:space-x-6 pl-14 sm:pl-0 sm:justify-end sm:mt-0">
                                <a href="#" title="" class="text-sm font-medium text-gray-400 transition-all duration-200 hover:text-gray-900"> Ver Mas </a>

                        
                            </div>
                        </div>
                    </div>

                    <div class="py-5">
                        <div class="sm:flex sm:items-center sm:justify-between sm:space-x-5">
                            <div class="flex items-center flex-1 min-w-0">
                                <img class="flex-shrink-0 object-cover w-12 h-12 rounded-full" src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/table-stacked/4/avatar-male-2.png" alt="" />
                                <div class="flex-1 min-w-0 ml-4">
                                    <p class="text-sm font-bold text-gray-900 truncate">Slack</p>
                                    <p class="mt-1 text-sm font-medium text-gray-500 truncate">Lorem ipsum dolor sit amet, consectetur adipis.</p>
                                </div>
                            </div>

                            <div class="flex items-center justify-between mt-4 sm:space-x-6 pl-14 sm:pl-0 sm:justify-end sm:mt-0">
                                <a href="#" title="" class="text-sm font-medium text-gray-400 transition-all duration-200 hover:text-gray-900"> Ver Mas </a>

                          
                            </div>
                        </div>
                    </div>

                    <div class="py-5">
                        <div class="sm:flex sm:items-center sm:justify-between sm:space-x-5">
                            <div class="flex items-center flex-1 min-w-0">
                                <img class="flex-shrink-0 object-cover w-12 h-12 rounded-full" src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/table-stacked/4/avatar-female-2.png" alt="" />
                                <div class="flex-1 min-w-0 ml-4">
                                    <p class="text-sm font-bold text-gray-900 truncate">Dropbox</p>
                                    <p class="mt-1 text-sm font-medium text-gray-500 truncate">Lorem ipsum dolor sit amet adipis.</p>
                                </div>
                            </div>

                            <div class="flex items-center justify-between mt-4 sm:space-x-6 pl-14 sm:pl-0 sm:justify-end sm:mt-0">
                                <a href="#" title="" class="text-sm font-medium text-gray-400 transition-all duration-200 hover:text-gray-900"> Ver Mas </a>

                          
                            </div>
                        </div>
                    </div>
                </div>
            </div>

      
        </div>
   
    </div>

</div>

    );
}

export default Contratistas;