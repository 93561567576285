import React, { useState } from 'react';

const ChatRow = (props) => {   




return (
    <div class="flex items-start gap-2.5">
{     props.mensaje.substring(0, props.mensaje.indexOf(":"))=='usuario983912'?  
            <img class="w-8 h-8 rounded-full" src="https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/5/avatar-male-1.png" alt="Jese image"></img>
            :
            <img class="w-8 h-8 rounded-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/4/avatar.jpg" alt="Jese image"></img>
        }  

   <div class="flex flex-col gap-1 w-full max-w-[320px]">
      <div class="flex items-center space-x-2 rtl:space-x-reverse">
         <span class="text-sm font-semibold text-gray-900 dark:text-white">{props.mensaje.substring(0, props.mensaje.indexOf(":"))}</span>
         <span class="text-sm font-normal text-gray-500 dark:text-gray-400">11:46</span>
      </div>
      <div class="flex flex-col leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
         <p class="text-sm font-normal text-gray-900 dark:text-white"> {props.mensaje.substring(props.mensaje.indexOf(":")+1)}</p>
      </div>
      <br></br>
      <br></br>
      {/*<span class="text-sm font-normal text-gray-500 dark:text-gray-400">Delivered</span> */}
   </div>

</div>
);


}
export default ChatRow;
