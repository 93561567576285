import React from 'react';
import Cta from '../components/Cta';
import Feature2 from '../components/Feature2';
import Precio from '../components/Precio';
import HowItWorks from '../components/HowItWorks';
import TextArea from '../components/TextArea';
import Input from '../components/Input';
import InputEmail from '../components/InputEmail';
import Contacto from '../components/Contacto';
import SuccessModal from '../components/SuccessModal';
import Sig3 from '../components/Sig3';

function AltaProveedorPaso3() {
  return (
    <div> 
      <Sig3 />
    </div>
  );
}

export default AltaProveedorPaso3;
