
import React from 'react';
//import logo from './logorecomendados1.png';
import { useNavigate } from 'react-router-dom';

/*     <img class="w-auto h-8" src={logo} alt="" /> */

/*
       <div>
                    <p class="text-xs font-bold tracking-widest text-gray-900 uppercase">Compania</p>
                    <ul class="mt-8 space-y-5">
                        <li>
                            <a href="#" title="" class="inline-flex text-sm font-normal text-gray-900 transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:translate-x-1"> Acerca </a>
                        </li>
                     
                    </ul>
                </div>
    
                <div>
                    <p class="text-xs font-bold tracking-widest text-gray-900 uppercase">Ayuda</p>
                    <ul class="mt-8 space-y-5">
                        <li>
                            <a href="#" title="" class="inline-flex text-sm font-normal text-gray-900 transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:translate-x-1"> Ofrece tus servicios</a>
                        </li>
                            <li>
                            <a href="#" title="" class="inline-flex text-sm font-normal text-gray-900 transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:translate-x-1"> Terminos & Condiciones </a>
                        </li>
                        <li>
                            <a href="#" title="" class="inline-flex text-sm font-normal text-gray-900 transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:translate-x-1"> Politica de Privacidad</a>
                        </li>
                    </ul>
                </div>
*/
const Footer = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        console.log('registra proveedor de servicio...');
        navigate('/altacliente'); // Change to the desired route
      };

      const handleClickFaq = () => {
        console.log('faq...');
        navigate('/preguntasfrecuentes'); // Change to the desired route
      };




    return (
        <footer class="pt-12 pb-8 bg-gray-200 sm:pt-16 lg:pt-20">
        <div class="px-4 mx-auto lg:px-8 sm:px-6 max-w-7xl">
            <div class="grid grid-cols-1 gap-y-12 sm:gap-x-8 sm:gap-y-16 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 lg:gap-x-12 xl:gap-x-16">
                <div class="lg:col-span-2 sm:col-span-3 xl:col-span-3">
                    <div class="lg:max-w-sm">
              
                        <p class="mt-8 text-sm font-normal leading-6 text-gray-600"></p>
                    </div>
                </div>
    
         
                <div>
                    <p class="text-xs font-bold tracking-widest text-gray-900 uppercase">Ayuda</p>
                    <ul class="mt-8 space-y-5">
                        <li>
                            <a href="#"  onClick={handleClick} title="" class="inline-flex text-sm font-normal text-gray-900 transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:translate-x-1">Registro de proveedores aqui</a>
                        </li>

                        <li>
                            <a href="#"  onClick={handleClickFaq} title="" class="inline-flex text-sm font-normal text-gray-900 transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:translate-x-1">Preguntas Frecuentes</a>
                        </li>
                    </ul>
                </div>

    
      
            </div>
    
            <hr class="mt-12 border-gray-200 sm:mt-16 lg:mt-20" />
    
            <div class="flex flex-col mt-8 sm:items-center sm:flex-row sm:justify-between">
                <p class="text-sm font-normal text-gray-600">© Copyright 2025. All Rights Reserved</p>
    
        
            </div>
        </div>
    </footer>
    );
}

export default Footer;