import React from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import { useState } from 'react';
import './App.css';

import Footer from './components/Footer';
import Home from './routes/Home';
import ContratistasLista from './routes/ContratistasLista';
import Perfilpage from './routes/Perfilpage';


import Login from './routes/Login';
import Perfil from './routes/Perfil';
import Registra from './routes/Registra';
import Portal from './routes/Portal';
import Requestors from './routes/Requestors';
import Detalles from './routes/Detalles';
import ShellApp from './routes/ShellApp';
import AltaProveedor from './routes/AltaProveedor';
import AltaClientePaso3 from './routes/AltaCliente_CapturaNumCelular';

import AltaProveedorPaso2 from './routes/AltaProveedorPaso2';
import AltaProveedorPaso3 from './routes/AltaProveedorPaso3';

import MakeAppt from './routes/MakeAppt';
import PreguntasFrecuentes from './routes/PreguntasFrecuentes';
import PageProveedorChat from './routes/PageProveedorChat';
import PageProveedorChatConvo from './routes/PageProveedorChatConvo';

import PageProveedorPerfil from './routes/PageProveedorPerfil';
import PageProveedorHome from './routes/PageProveedorHome';
import PageProveedorFavoritos from './routes/PageProveedorFavoritos';

import PageCliente from './routes/PageCliente';
import UserContext from './components/User';


import AccesoUsuario from './routes/AccesoUsuario';


import AltaCliente_CapturaNombreEmail from './routes/AltaCliente_CapturaNombreEmail'; //ok
import AltaCliente_VerificacionEmail from './routes/AltaCliente_VerificacionEmail'; //ok
import AltaCliente_CapturaNumCelular from './routes/AltaCliente_CapturaNumCelular'; //ok
import AltaCliente_CapturaPassword from './routes/AltaCliente_CapturaPassword'; //ok
import AltaCliente_VerificacionNumCelular from './routes/AltaCliente_VerificacionNumCelular'; //ok
import PageHome from './routes/PageHome';
import PagePerfil from './routes/PagePerfil';
import PageFavoritos from './routes/PageFavoritos';
import PageChat from './routes/PageChat';
import PageChatConvo from './routes/PageChatConvo';

const user = {
  nombre: '',
  email:'',
  celular:'',
  codigoverificacion:0,
  clave:'',
  mayor: 0,
  credito: 0,
  loggedproveedor: false,
  loggedcliente: false,
  tipodecambio: 20,
  favorites: [
    'avocado',
    'carrot'
  ]
}

const items = [
  { id: 1, name:'Alice', age:30 },
  { id: 2, name:'Bob', age:24 },
  { id: 3, name:'Charlie', age:28 }
  
];

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [myArray, setMyArray] = useState([]);

    const addObject = (newObject) => {
      setMyArray([...myArray, newObject]);
    };


    return (
      <div className="App">

      <UserContext.Provider value={user, items, { myArray, addObject }}>
          <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contratistas" element={<ContratistasLista />} />   
                 
                <Route path="/perfil" element={<Perfilpage />} />   
                <Route path="/perfilproveedorok" element={<PageProveedorPerfil />} />
                <Route path="/pperfil" element={<PagePerfil />} />

                <Route path="/altacliente" element={<AltaCliente_CapturaNombreEmail />} />   
                <Route path="/altaclientepaso2" element={<AltaCliente_VerificacionEmail />} />
                <Route path="/altaclienteclave" element={<AltaCliente_CapturaPassword />} />  
                <Route path="/altacelular" element={<AltaCliente_CapturaNumCelular />} /> 
                <Route path="/altacelularpaso2" element={<AltaCliente_VerificacionNumCelular />} /> 
                
                <Route path="/home" element={<PageHome />} />
                <Route path="/favoritos" element={<PageFavoritos />} />
                <Route path="/chat" element={<PageChat />} />
                <Route path="/chatconvo" element={<PageChatConvo />} />
                <Route path="/accesousuario" element={<AccesoUsuario />} />  
                <Route path="/altaproveedor2" element={<AltaProveedorPaso2 />} />
                <Route path="/altaproveedor3" element={<AltaProveedorPaso3 />} />
                <Route path="/proveedorchat" element={<PageProveedorChat />} />
                <Route path="/proveedorchatconvo" element={<PageProveedorChatConvo />} />

                <Route path="/proveedorperfil" element={<PageProveedorPerfil />} />
                <Route path="/proveedorhome" element={<PageProveedorHome />} />
                <Route path="/proveedorfavoritos" element={<PageProveedorFavoritos />} />
                <Route path="/preguntasfrecuentes" element={<PreguntasFrecuentes />} />
            </Routes>

        </Router>
      </UserContext.Provider>   
      </div>
    );
}

export default App;
