import React from 'react';
import Cta from '../components/Cta';
import Feature2 from '../components/Feature2';
import Precio from '../components/Precio';
import HowItWorks from '../components/HowItWorks';
import TextArea from '../components/TextArea';
import Input from '../components/Input';
import InputEmail from '../components/InputEmail';
import Contacto from '../components/Contacto';
import SuccessModal from '../components/SuccessModal';
import FormaRegistroCelular from '../components/FormaRegistroCelularOld';
import FormaRegistroPassword from '../components/registroyacceso/FormaRegistroPassword';

function AltaCliente_CapturaPassword() {
  return (
    <div> 
         <FormaRegistroPassword />
    </div>
  );
}

export default AltaCliente_CapturaPassword;
