import React,{ useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import Footer from './Footer';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from './User';

function EscogerElCorrecto() {
    const user = useContext(UserContext);

    const navigate = useNavigate();

    //onClick={handleClick}
    //import { useNavigate } from 'react-router-dom';
    const handleClick = () => {
        console.log('registro de cliente...');
       // navigate('/altacliente'); // Change to the desired route
        navigate('/accesousuario'); // Change to the desired route
        
    };

    return (

        
<section class="py-12 bg-gray-50 sm:py-16 lg:py-20">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 md:items-center gap-y-8 md:grid-cols-2 md:gap-x-16">
            <div>
                <img class="w-full max-w-lg mx-auto" src="https://cdn.rareblocks.xyz/collection/clarity/images/features/3/illustration.png" alt="" />
            </div>

            <div class="lg:pr-12">
                <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Te ayudamos a elegir a la persona correcta</h2>
   
                <p class="mt-5 text-base leading-relaxed text-gray-600">Si te gustan la experiencia y las recomendaciones de alguien, puedes chatear con esta persona dentro de esta app, para asi aclarar todas las dudas que tengas y ponerse de acuerdo. <br></br><br></br>Sólo necesitas registrarte gratis.</p>
     
                <div class="flex flex-col items-center justify-center px-16 mt-8 space-y-4 sm:space-y-0 sm:space-x-4 sm:flex-row lg:mt-12 sm:px-0">
                   <a href="#" onClick={handleClick} title="" class="inline-flex items-center justify-center w-full px-8 py-4 text-base font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md sm:w-auto hover:bg-blue-700 focus:bg-blue-700" role="button"> Acceso </a>
                </div>

            </div>
        </div>
    </div>
    <br></br>
    <br></br>


</section>
    )
}
export default EscogerElCorrecto;




