import React,{ useEffect, useState } from 'react';
import { useContext } from 'react';
import UserContext from './User';
import {useRef} from 'react'
import ReactDOM from 'react-dom'


//  {user.loggedproveedor? <p>Logged in</p>: <p>Logged off</p>}
function IniciaProveedor(){
    const [num, setNum] = useState(1);

    //import UserContext from './User';
    const user = useContext(UserContext);

    user.loggedproveedor=true;
    return (
        <div>
          
        </div>
          
 
       
    );
}

export default IniciaProveedor;