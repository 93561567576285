import React from 'react';
import Cta from '../components/Cta';
import Feature2 from '../components/Feature2';
import Precio from '../components/Precio';
import HowItWorks from '../components/HowItWorks';
import TextArea from '../components/TextArea';
import Input from '../components/Input';
import InputEmail from '../components/InputEmail';
import Contacto from '../components/Contacto';
import SuccessModal from '../components/SuccessModal';
import ChatRoomProveedor from '../components/ChatRoomProveedor';
import MenuAbajoProveedor from '../components/MenuAbajoProveedor';
import Perfil from '../components/Perfil';
import IniciaProveedor from '../components/IniciaProveedor';
import PerfilProveedor from '../components/PerfilProveedor';
import PageTitle from '../components/PageTitle';
import InformacionDeContacto from '../components/InformacionDeContacto';
import PerfilProveedorFormato2 from '../components/PerfilProveedorFormato2';

function PageProveedorPerfil() {
  return (
    <div> 
      <PageTitle title="Perfil" />
      <IniciaProveedor />
      <PerfilProveedor />
      {/*<PerfilProveedorFormato2 /> */}
      <InformacionDeContacto />

      <MenuAbajoProveedor />

    </div>
  );
}

export default PageProveedorPerfil;
