import React,{ useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import { Accordion } from "flowbite-react";

function Faq() {


    return (

        


           <section class="py-12 bg-gray-50 sm:py-16 lg:py-20">
                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div class="text-center">
                        <h2 class="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">Preguntas Frecuentes</h2>
                    </div>

                    <div class="max-w-5xl mx-auto mt-10 sm:mt-20">


                    <Accordion collapseAll>
      <Accordion.Panel>
        <Accordion.Title>   <p class="text-2xl font-bold text-gray-900 font-pj">¿Cuál es el beneficio de utilizar “Recomendados.MX”?</p></Accordion.Title>
        <Accordion.Content>
        <p class="mt-8 text-lg font-normal text-gray-600 font-pj">
                                    Te facilita el contacto directo con algún técnico o especialista en muchos tipos de oficios, como Plomeros, Electricistas, Cerrajeros, Albañiles, Asistentes de limpieza, etc. Cercanos a tu ubicación, con niveles de reputación e insignias que te permitirán elegir al “recomendado” ideal, para tu necesidad.
                                    </p>
        </Accordion.Content>
      </Accordion.Panel>


      <Accordion.Panel>
        <Accordion.Title>   <p class="text-2xl font-bold text-gray-900 font-pj">¿Para qué se utiliza la dirección que me solicitan para la búsqueda?</p></Accordion.Title>
        <Accordion.Content>
        <p class="mt-8 text-lg font-normal text-gray-600 font-pj">
                                    la información de ubicación solicitada, es solamente para fines de hacer una búsqueda más selectiva y apropiada, Técnicos o especialistas más cercanos a tu ubicación puede permitir el ofrecer un servicio más rápido y con menores costos para ti.  Si crees conveniente, solo puedes indicar la información de la colonia y se logra estos mismos criterios de búsqueda.
                                    </p>
        </Accordion.Content>
      </Accordion.Panel>

      <Accordion.Panel>
        <Accordion.Title>      <p class="text-2xl font-bold text-gray-900 font-pj">¿Los Técnicos, especialistas o “recomendados” pagan para publicarse en este portal?</p>
                          </Accordion.Title>
        <Accordion.Content>
        <p class="mt-8 text-lg font-normal text-gray-600 font-pj">
                                    No, ellos se registran gratis y son trabajadores independientes que andan buscando a quien ofrecer sus servicios. Y nuestro portal es un facilitador para ayudarles en conectar con sus clientes, quienes tienen una necesidad o urgencia por atender.  Nuestra misión es lograr esta conexión, de una manera simple, confiable y segura para ambos.
                                    </p>
        </Accordion.Content>
      </Accordion.Panel>



      <Accordion.Panel>
        <Accordion.Title>   <p class="text-2xl font-bold text-gray-900 font-pj">¿Por qué ocupo registrarme para tener la información de algún “recomendado”? </p></Accordion.Title>
        <Accordion.Content>
        <p class="mt-8 text-lg font-normal text-gray-600 font-pj">Nuestro propósito es conectar “Clientes” con Técnicos o Especialistas en algún oficio, de manera segura, para ambos, para esto ocupamos para ambos, cumplir con ciertos datos básicos de identidad y ubicación, no te preocupes tu información estará segura.  Adicional esta información de registro nos permitirá hacer el contacto entre ambos , más simple y rápida.</p>
                        
        </Accordion.Content>
      </Accordion.Panel>

      <Accordion.Panel>
        <Accordion.Title>   <p class="text-2xl font-bold text-gray-900 font-pj">¿Qué pasa si alguno de los Técnicos o Especialistas que contacte no me responde o no asiste? </p></Accordion.Title>
        <Accordion.Content>
        <p class="mt-8 text-lg font-normal text-gray-600 font-pj">
                                    No existe mayor problema, en estos casos podrías presentar una evaluación de este contacto, para que se refleje en su reputación dentro del portal y los futuros posibles clientes conozcan este punto, lo que posiblemente pueda afectar su volumen de contactos, lo que forzara a quienes ofrecen su servicio, a buscar ofrecer siempre su mejor atención y compromiso, si es que desean incrementar sus ingresos.          Adicional te permitiremos buscar otros Técnicos con buena reputación, para que puedas elegir al mejor basado en la retroalimentación de clientes previos. </p>
                        
        </Accordion.Content>
      </Accordion.Panel>


                    </Accordion>






                    </div>
                </div>
            </section>
    )
}
export default Faq;




