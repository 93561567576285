import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from "flowbite-react";

function Mensajes(props){

   const navigate = useNavigate();

   const handleClickChat = () => {
       console.log('chat convo..');
       navigate('/chatconvo'); // Change to the desired route
   };




return (

<div >
  


<ul class="max-w-md divide-y divide-gray-200 dark:divide-gray-700">





   <Card hoverable onClick={handleClickChat} class="block hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
   
      <div class="flex items-center space-x-1 rtl:space-x-reverse">
         <div class="shrink-0">
            <img class="w-14 h-14 rounded-full" src="https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/5/avatar-male-1.png" alt="Neil image" />
         </div>
         <div class="flex-1 min-w-0">
            <p class="text-left text-md font-bold text-gray-900 truncate dark:text-white">
               Viva Te Escucha
            </p>
            <p class="text-sm text-gray-500 truncate dark:text-gray-400">
               Apologies if you're been bothered by
            </p>
            <p class="text-sm text-gray-500 truncate dark:text-gray-400">
               my msg. I only admire some of your
            </p>
         </div>
         <div class="inline-flex items-center text-base font-regular text-gray-400 dark:text-white">
            10/10/2010
         </div>
      </div>
   </Card>


  {/*} <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Upload file</label>
<input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" />
*/}


  
</ul>

</div>
);


}
export default Mensajes;
