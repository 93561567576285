import React from 'react';
import Cta from '../components/Cta';
import Feature2 from '../components/Feature2';
import Precio from '../components/Precio';
import HowItWorks from '../components/HowItWorks';
import TextArea from '../components/TextArea';
import Input from '../components/Input';
import InputEmail from '../components/InputEmail';
import Contacto from '../components/Contacto';
import SuccessModal from '../components/SuccessModal';
import Sig2 from '../components/Sig2';

function AltaProveedorPaso2() {
  return (
    <div> 
      <Sig2 />
    </div>
  );
}

export default AltaProveedorPaso2;
