import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Cta from '../components/Cta';
import Perfil from '../components/Perfil';

import HowItWorks from '../components/HowItWorks';
import PrecioInicial from '../components/PrecioInicial';
import Precio from '../components/Precio';
import Precio2 from '../components/Precio2';
import PrecioSoloLlamada from '../components/PrecioSoloLlamada';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import tecnicos from './tecnicos.js';
import areas from './area.js';
//import marcasfile from './marcas.js';
import { Label, TextInput } from "flowbite-react";
import { Card } from "flowbite-react";
import Grid from '@mui/material/Grid';
import Comentarios  from '../components/Comentarios';
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import CtaRegistroDeCliente  from '../components/CtaRegistroDeCliente';


function Perfilpage() {
  const [value, setValue] = React.useState();

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    console.log('lista de contratistas...');
    navigate('/'); // Change to the desired route
  };

  const handleClick2 = () => {
    console.log('lista de contratistas...');
    navigate('/contratistas'); // Change to the desired route
  };



  return (
    <div>
      <Header />
    <div class="relative px-0 mx-auto sm:max-w lg:px-0 max-w">
        <div class="max-w-xl mx-auto text-center">
        
            <div class="mt-8 sm:mt-10">

            <Breadcrumb aria-label="Default breadcrumb example">
                <Breadcrumb.Item onClick={handleClick} href="#" icon={HiHome}>Inicio</Breadcrumb.Item>
                <Breadcrumb.Item onClick={handleClick2} href="#" >Tecnicos</Breadcrumb.Item>
                <Breadcrumb.Item>Perfil</Breadcrumb.Item>
            </Breadcrumb>
            <br></br>
            <Perfil />
            </div>
   
        </div>
        <CtaRegistroDeCliente />
        <br></br>
    </div>
    <Footer />
    </div>
  );
}

export default Perfilpage;
