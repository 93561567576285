import React, { useState, useEffect } from 'react';
import { io } from "socket.io-client";
import ChatRow from '../components/ChatRow';
// "undefined" means the URL will be computed from the `window.location` object
///const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:8083';

///const socket = io.connect(URL, { query: "chatRoomId=123&userId=983912" });
//const socket = io.connect(URL,{ path: 'socket.io'}, {query: "chatRoomId=123&userId=983912" });



const socket = io.connect('', { path: "/chat/", query: "chatRoomId=123&userId=983912" });
//dev const socket = io.connect('http://localhost:8080', {  path: "/chat/", query: "chatRoomId=123&userId=983912" });

function ChatRoom() {
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [user, setUser] = useState(null);
  const [inputValue, setInputValue] = useState('');

  


  // Send a message
  const sendMessageChat = () => {
    socket.emit('sendMessage', 983912, messageText);
    setMessageText('');
  };

  


  // Receive messages
  useEffect(() => {
    socket.on('receiveMessage', (messageR) => {
      setMessages(messages => [...messages, messageR]);
    });
  }, []);


    // Join a chat room
  const joinChatRoom = (userDetails) => {
     setUser(userDetails);
     socket.emit('join', userDetails);
   };
  
   // Leave a chat room
   const leaveChatRoom = () => {
     socket.emit('leave', user);
     setUser(null);
  };
  // More code will go here


  
  return (

    <div class="py-12 bg-white sm:py-12 lg:py-20">
              <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                {messages.map((msg, index) => (
                  < ChatRow mensaje={msg} />
                ))}


              <br></br>
              <div className="chat-room">
                  <input 
                  className="block w-full px-4 py-4 text-base text-gray-900 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
                  value={messageText} onChange={(e) => setMessageText(e.target.value)} />

                  <br></br>
                  <button onClick={sendMessageChat} style={{ 
                                backgroundColor: '#fb8500', 
                                color: 'white'
                              }}
        
                              className="inline-flex items-center justify-center px-12 py-4 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700">
                    Mandar mensaje
                  </button>
              </div>
            </div>               
      </div>
      );
}

export default ChatRoom;
