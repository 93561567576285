import React from 'react';
import Cta from '../components/Cta';
import Feature2 from '../components/Feature2';
import Precio from '../components/Precio';
import HowItWorks from '../components/HowItWorks';
import TextArea from '../components/TextArea';
import Input from '../components/Input';
import InputEmail from '../components/InputEmail';
import Contacto from '../components/Contacto';
import SuccessModal from '../components/SuccessModal';
import ChatRoomProveedor from '../components/ChatRoomProveedor';
import MenuAbajoProveedor from '../components/MenuAbajoProveedor';
import IniciaProveedor from '../components/IniciaProveedor';
import PageTitle from '../components/PageTitle';
import Mensajes from '../components/Mensajes';
import MenuAbajoCliente2 from '../components/MenuAbajoCliente2';

//<IniciaProveedor />
//<ChatRoomProveedor />


function PageChatConvo() {
  return (
    <div> 


      <div class="fixed top-15 left-0 z-50  h-20 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">   
        <PageTitle title="Mensajes: Chat Convo" />
      </div>

      <ChatRoomProveedor/>


      <MenuAbajoCliente2 />

    </div>
  );
}

export default PageChatConvo;
