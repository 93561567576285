import React, { useState } from 'react';

const InformacionDeContacto = (props) => {   




return (

<div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
  


<dl class="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
    <div class="flex flex-col pb-3">
        <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Email</dt>
        <dd class="text-lg font-semibold">rcelizarr@gmail.com.com</dd>
    </div>
    <div class="flex flex-col py-3">
        <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Direccion</dt>
        <dd class="text-lg font-semibold">92 Miles Drive, Newark, NJ 07103, California, USA</dd>
    </div>
    <div class="flex flex-col pt-3">
        <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Telefono Celular</dt>
        <dd class="text-lg font-semibold">210 913 0693</dd>
    </div>
</dl>


</div>
);


}
export default InformacionDeContacto;
