import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

import Cta from '../components/Cta';
import Contratistas from '../components/Contratistas';
import Features from '../components/Features';

import HowItWorks from '../components/HowItWorks';
import PrecioInicial from '../components/PrecioInicial';
import Precio from '../components/Precio';
import Precio2 from '../components/Precio2';
import PrecioSoloLlamada from '../components/PrecioSoloLlamada';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import GoogleMaps from '../components/GoogleMaps';

import tecnicos from './tecnicos.js';
import areas from './area.js';
//import marcasfile from './marcas.js';
import { Label, TextInput } from "flowbite-react";
import { Card } from "flowbite-react";
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import Faq from '../components/Faq';
import EscogerElCorrecto from '../components/EscogerElCorrecto';


/*
 <section class="py-12 bg-white sm:py-16 lg:py-20">
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="text-center">
            <h2 class="text-2xl font-bold text-gray-900 sm:text-3xl">O Seleccione una Categoria para empezar</h2>
        </div>

        <div class="grid grid-cols-1 gap-5 mt-8 sm:mt-12 sm:grid-cols-2 xl:grid-cols-3 sm:gap-8 xl:gap-12">
            <div class="relative overflow-hidden transition-all duration-200 bg-gray-100 rounded-xl hover:bg-gray-200">
                <div class="p-6 lg:px-10 lg:py-8">
                    <div class="flex items-center justify-start space-x-8">
                        <svg class="flex-shrink-0 w-10 h-10 text-gray-600 md:w-12 md:h-12" viewBox="0 0 60 60" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.75 10C37.75 14.51 34.06 18.2 29.55 18.2C25.04 18.2 21.35 14.51 21.35 10H15.2L7 14.1V26.4H15.2V51H43.9V26.4H52.1V14.1L43.9 10H37.75Z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div class="flex-shrink-0 w-px h-12 bg-gray-200"></div>
                        <div>
                            <h3 class="text-sm font-bold text-gray-900 sm:text-base lg:text-lg">
                                <a href="#" title="">
                                    Albanil
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                </a>
                            </h3>
                            <p class="mt-2 text-sm font-medium text-gray-500">983 Available Products</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="relative overflow-hidden transition-all duration-200 bg-gray-100 rounded-xl hover:bg-gray-200">
                <div class="p-6 lg:px-10 lg:py-8">
                    <div class="flex items-center justify-start space-x-8">
                        <svg class="flex-shrink-0 w-10 h-10 text-gray-600 md:w-12 md:h-12" viewBox="0 0 60 60" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M44 52H16C11.6 52 8 48.4 8 44V20H52V44C52 48.4 48.4 52 44 52Z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M40 26V18C40 12.5 35.5 8 30 8C24.5 8 20 12.5 20 18V26" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M36 34H24V42H36V34Z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div class="flex-shrink-0 w-px h-12 bg-gray-200"></div>
                        <div>
                            <h3 class="text-sm font-bold text-gray-900 sm:text-base lg:text-lg">
                                <a href="#" title="">
                                    Electricista
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                </a>
                            </h3>
                            <p class="mt-2 text-sm font-medium text-gray-500">142 Available Products</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="relative overflow-hidden transition-all duration-200 bg-gray-100 rounded-xl hover:bg-gray-200">
                <div class="p-6 lg:px-10 lg:py-8">
                    <div class="flex items-center justify-start space-x-8">
                        <svg class="flex-shrink-0 w-10 h-10 text-gray-600 md:w-12 md:h-12" viewBox="0 0 60 60" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 32V48H12C12 48 12 40.7 12 36C12 30.1 8 27.9 8 22C8 15.2 12 12 12 12L35.1 33.3C36.9 35 39.4 36 41.9 36H46C49.3 36 52 38.7 52 42V48C52 48 50 48 42 48C34 48 23.5 32 18 32Z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div class="flex-shrink-0 w-px h-12 bg-gray-200"></div>
                        <div>
                            <h3 class="text-sm font-bold text-gray-900 sm:text-base lg:text-lg">
                                <a href="#" title="">
                                    Plomero
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                </a>
                            </h3>
                            <p class="mt-2 text-sm font-medium text-gray-500">476 Available Products</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="relative overflow-hidden transition-all duration-200 bg-gray-100 rounded-xl hover:bg-gray-200">
                <div class="p-6 lg:px-10 lg:py-8">
                    <div class="flex items-center justify-start space-x-8">
                        <svg class="flex-shrink-0 w-10 h-10 text-gray-600 md:w-12 md:h-12" viewBox="0 0 60 60" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30 18L24 11L26 8H34L36 11L30 18Z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M30 52C39.3888 52 47 44.3888 47 35C47 25.6112 39.3888 18 30 18C20.6112 18 13 25.6112 13 35C13 44.3888 20.6112 52 30 52Z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M30 47C36.6274 47 42 41.6274 42 35C42 28.3726 36.6274 23 30 23C23.3726 23 18 28.3726 18 35C18 41.6274 23.3726 47 30 47Z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div class="flex-shrink-0 w-px h-12 bg-gray-200"></div>
                        <div>
                            <h3 class="text-sm font-bold text-gray-900 sm:text-base lg:text-lg">
                                <a href="#" title="">
                                    Limpieza
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                </a>
                            </h3>
                            <p class="mt-2 text-sm font-medium text-gray-500">849 Available Products</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="relative overflow-hidden transition-all duration-200 bg-gray-100 rounded-xl hover:bg-gray-200">
                <div class="p-6 lg:px-10 lg:py-8">
                    <div class="flex items-center justify-start space-x-8">
                        <svg class="flex-shrink-0 w-10 h-10 text-gray-600 md:w-12 md:h-12" viewBox="0 0 60 60" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 19.417L20 8H40L42 19.417" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18 40.583L20 52H40L42 40.583" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M30 46C38.8366 46 46 38.8366 46 30C46 21.1634 38.8366 14 30 14C21.1634 14 14 21.1634 14 30C14 38.8366 21.1634 46 30 46Z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M30 22V30H38" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div class="flex-shrink-0 w-px h-12 bg-gray-200"></div>
                        <div>
                            <h3 class="text-sm font-bold text-gray-900 sm:text-base lg:text-lg">
                                <a href="#" title="">
                                    Cerrajero
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                </a>
                            </h3>
                            <p class="mt-2 text-sm font-medium text-gray-500">253 Available Products</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="relative overflow-hidden transition-all duration-200 bg-gray-100 rounded-xl hover:bg-gray-200">
                <div class="p-6 lg:px-10 lg:py-8">
                    <div class="flex items-center justify-start space-x-8">
                        <svg class="flex-shrink-0 w-10 h-10 text-gray-600 md:w-12 md:h-12" viewBox="0 0 60 60" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M30 44.625C31.5533 44.625 32.8125 43.3658 32.8125 41.8125C32.8125 40.2592 31.5533 39 30 39C28.4467 39 27.1875 40.2592 27.1875 41.8125C27.1875 43.3658 28.4467 44.625 30 44.625Z"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M41.25 50.25H18.75C17.7554 50.25 16.8016 49.8549 16.0983 49.1516C15.3951 48.4484 15 47.4946 15 46.5V12.75C15 11.7554 15.3951 10.8016 16.0983 10.0983C16.8016 9.39509 17.7554 9 18.75 9H41.25C42.2446 9 43.1984 9.39509 43.9016 10.0983C44.6049 10.8016 45 11.7554 45 12.75V46.5C45 47.4946 44.6049 48.4484 43.9016 49.1516C43.1984 49.8549 42.2446 50.25 41.25 50.25Z"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <div class="flex-shrink-0 w-px h-12 bg-gray-200"></div>
                        <div>
                            <h3 class="text-sm font-bold text-gray-900 sm:text-base lg:text-lg">
                                <a href="#" title="">
                                    Mudanza
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                </a>
                            </h3>
                            <p class="mt-2 text-sm font-medium text-gray-500">94 Available Products</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>





     <Autocomplete
                id="free-solo-demo2"
                freeSolo
                value={value2}
                forcePopupIcon={false}
                freeSolo={false}
                popupIcon={""}
                onChange={handleChange2}
                disablePortal
                options={areas}
                sx={{ width: 350 }}
                renderInput={(params) => <TextField {...params} label="Escriba su direccion" />}
            />

              <GoogleMaps />
*/

function Home() {
  const [value, setValue] = React.useState();
  const [value2, setValue2] = React.useState();
  const [options, setOptions] = React.useState([]);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const navigate = useNavigate();
//import { useNavigate } from 'react-router-dom';
  const handleClick = () => {
    console.log('lista de contratistas...');
    navigate('/contratistas'); // Change to the desired route
  };



  return (
    <div>
        <Header />
        <Cta />




      <div class="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <br></br>
        <Card className="max-w">
          <div className="sm:flex font-container text items-center justify-left">
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              value={value}
              forcePopupIcon={false}
              freeSolo={true}
              popupIcon={""}
              onChange={handleChange1}
              disablePortal

              //options={options}
              options={tecnicos}
              sx={{ width: 310 }}
              renderInput={(params) => <TextField {...params} label="Escriba un oficio" />}
            />
            <span> </span>
            <br></br>
            <GoogleMaps />
            <span> </span>


            <br></br>
            <span> </span>
            <div>{" "}</div>
            <a
              href="#"
              title=""
              class="inline-flex items-center justify-center px-8 py-3 text-base font-bold leading-7 text-gray-900 transition-all duration-200 bg-yellow-100 border border-transparent rounded-md hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
              role="button"
              onClick={handleClick}
            >
              Explorar
            </a>

          </div>
        </Card>


        <div class="max-w-xl mx-auto text-center">



          <div class="mt-8 sm:mt-10">

          </div>
        </div>
      </div>
      <Features />
      <EscogerElCorrecto />
      <Footer />

    </div>
  );
}

export default Home;
