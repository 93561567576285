import React from 'react';
import Cta from '../components/Cta';
import Feature2 from '../components/Feature2';
import Precio from '../components/Precio';
import HowItWorks from '../components/HowItWorks';
import TextArea from '../components/TextArea';
import Input from '../components/Input';
import InputEmail from '../components/InputEmail';
import Contacto from '../components/Contacto';
import SuccessModal from '../components/SuccessModal';
import ChatRoomProveedor from '../components/ChatRoomProveedor';
import MenuAbajoProveedor from '../components/MenuAbajoProveedor';
import IniciaProveedor from '../components/IniciaProveedor';
import PageTitle from '../components/PageTitle';
import Mensajes from '../components/Mensajes';
import MenuAbajoCliente2 from '../components/MenuAbajoCliente2';

//<IniciaProveedor />
//<ChatRoomProveedor />


function PageChat() {
  return (
    <div> 

      <PageTitle title="Mensajes" />
      <Mensajes />


      <MenuAbajoCliente2 />

    </div>
  );
}

export default PageChat;
