import React,{ useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Carousel } from "flowbite-react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
//import tecnicos from './tecnicosToDelete.js';
import areas from './area.js';
import { Card } from "flowbite-react";
import ChatRoom from './ChatRoom';
import { Avatar } from "flowbite-react";
import { Button } from "flowbite-react";
//  <ChatRoom />
import ProfileRating from '../components/ProfileRating';
import UserContext from './User';
import { useContext } from 'react';
// const user = useContext(UserContext);

function PerfilProveedor(){

    const user = useContext(UserContext);

    const [file, setFile] = useState(null);

    const handleChange = function loadFile(event) {
        if (event.target.files.length > 0) {
            const file = URL.createObjectURL(event.target.files[0]);
            setFile(file);
        }
    };

    return (


     
   
    


        <section class2=" sm:py-16 lg:py-24py-10 bg-white">



             
        




    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="text-center">
            <input type="file" onChange={handleChange} id="upload" accept="image/*" style={{display: "none"}}/>
            <label htmlFor="upload">











            {file ? (
                <div>
                  {/*}  <img class="w-20 h-20 rounded-full mx-auto" src={file} alt="" />
                    <img class="w-20 h-20 rounded-sm mx-auto " src={file} alt="Large avatar" />
                    <img class="w-36 h-36 rounded-sm  mx-auto" src={file} alt="Extra large avatar" />
                    <img class="w-20 h-20 rounded-full mx-auto" src={file} alt="Extra small avatar" />
                */}
                    <img class="w-36 h-36 rounded-full mx-auto" src={file} alt="Extra small avatar" />
              </div>
        
      ) : (

        <div>
            <div class="mx-auto relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
    <svg class="absolute w-12 h-12 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
</div>
        <p>Suba su foto aqui</p>
        </div>
      )}






          
            </label>
            <label htmlFor="avatar"/>
            
            <p class="mt-6 text-lg font-bold text-black">{user.nombre}
            {/*<span class="font-normal text-gray-600">Monterrey</span> */}
            <div class="max-w-lg mx-auto">
            <ProfileRating />
            </div>
            
            </p>
           
    
         
       

            <blockquote class="max-w-xl mx-auto mt-7">
                <p class="text-left text-gray-500 whitespace-normal dark:text-gray-400">
                The 1-800-Plumber +Air team has the expertise to meet your needs with an easy to remember name and phone number, so call today for help with all your East San Antonio home or business plumbing, drain cleaning, and heating and cooling needs.
                </p>
               

                <p class="text-left text-gray-500 whitespace-normal dark:text-gray-400">
                <br></br>
                <br></br>
                Services Offered:<br></br>
- Emergency plumbing<br></br>
- Garbage disposal installation<br></br>
- Gas line installation<br></br>
- Plumbing drain repair<br></br>
- Plumbing pipe installation or replacement<br></br>
- Plumbing pipe repair<br></br>
- Septic system installation or replacement<br></br>
- Septic system repair, maintenance or inspection<br></br>
- Shower and bathtub installation or replacement<br></br>
- Shower and bathtub repair<br></br>
- Sink or faucet installation or replacement<br></br>
- Sink or faucet repair<br></br>
- Sump pump installation or replacement<br></br>
- Toilet installation or replacement<br></br>
- Toilet repair<br></br>
- Water heater installation or replacement<br></br>
- Water heater repair or maintenance<br></br>
- Water treatment repair or maintenance<br></br>
- Water treatment system installation or replacement<br></br>
                </p>
            </blockquote>
        </div>

        <br></br>
        {file}
      
    </div>
<br></br>






<div class="grid grid-cols-2 gap-2">
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-1.jpg" alt="" />
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-2.jpg" alt="" />
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-3.jpg" alt="" />
        </div>
        <div>
            <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-4.jpg" alt="" />
        </div>
    </div>







</section>

    );
}

export default PerfilProveedor;


