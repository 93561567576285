import React,{ useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from "flowbite-react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
//import tecnicos from './tecnicosToDelete.js';
import areas from './area.js';
import { Card } from "flowbite-react";
import ChatRoom from './ChatRoom';
import { Avatar } from "flowbite-react";
import { Button } from "flowbite-react";
//  <ChatRoom />
import ProfileRating from './ProfileRating';
import UserContext from './User';
import { useContext } from 'react';
import { Badge } from "flowbite-react";

// const user = useContext(UserContext);

function PerfilUsuario(){

    const user = useContext(UserContext);

    const [file, setFile] = useState(null);

    const handleChange = function loadFile(event) {
        if (event.target.files.length > 0) {
            const file = URL.createObjectURL(event.target.files[0]);
            setFile(file);
        }
    };

    return (


     
   
    


        <section class2=" sm:py-16 lg:py-24py-10 bg-white">



             
        




    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="text-center">
            <input type="file" onChange={handleChange} id="upload" accept="image/*" style={{display: "none"}}/>
            <label htmlFor="upload">











            {file ? (
                <div>
                  {/*}  <img class="w-20 h-20 rounded-full mx-auto" src={file} alt="" />
                    <img class="w-20 h-20 rounded-sm mx-auto " src={file} alt="Large avatar" />
                    <img class="w-36 h-36 rounded-sm  mx-auto" src={file} alt="Extra large avatar" />
                    <img class="w-20 h-20 rounded-full mx-auto" src={file} alt="Extra small avatar" />
                */}
                    <img class="w-36 h-36 rounded-full mx-auto" src={file} alt="Extra small avatar" />
              </div>
        
      ) : (

        <div>
            <div class="mx-auto relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
    <svg class="absolute w-12 h-12 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
</div>
        <p>Suba su foto aqui</p>
        </div>
      )}






          
            </label>
            <label htmlFor="avatar"/>
            
            <p class="mt-6 text-lg font-bold text-black">{user.nombre}
            </p><br></br>

         
            <div className="flex flex-wrap gap-2">
            {user.email}
            {user.email && <Badge color="success">Verificado</Badge> }
            </div>

            <div className="flex flex-wrap gap-2">
            {user.celular}
            {user.celular && <Badge color="success">Verificado</Badge> }
            </div>



            
      
           
    
      
        </div>

        <br></br>
        {file}
      
    </div>
<br></br>














</section>

    );
}

export default PerfilUsuario;


