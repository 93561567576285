const tecnicos = [
  {
    codigo: "00191",
    label: "Albañil",
    TIPO_CLIENTE: "B",
    DIAS_CREDITO: 90,
    FINANC: 5
  },
  {
    codigo: "00574",
    label: "Carpintero",
    TIPO_CLIENTE: "B",
    DIAS_CREDITO: 30,
    FINANC: 0
  },
  {
    codigo: "00967",
    label: "Cerrajero",
    TIPO_CLIENTE: "B",
    DIAS_CREDITO: 45,
    FINANC: 0
  },
  {
    codigo: 19586,
    label: "Electricista",
    TIPO_CLIENTE: "A",
    DIAS_CREDITO: 0,
    FINANC: ""
  },
  {
    codigo: 19586,
    label: "Fumigador",
    TIPO_CLIENTE: "A",
    DIAS_CREDITO: 0,
    FINANC: ""
  },
  {
    codigo: 19586,
    label: "Jardinero",
    TIPO_CLIENTE: "A",
    DIAS_CREDITO: 0,
    FINANC: ""
  }
  ,
  {
    codigo: 19586,
    label: "Limpieza",
    TIPO_CLIENTE: "A",
    DIAS_CREDITO: 0,
    FINANC: ""
  }
  ,
  {
    codigo: 19586,
    label: "Plomero",
    TIPO_CLIENTE: "A",
    DIAS_CREDITO: 0,
    FINANC: ""
  },
  {
    codigo: 19586,
    label: "Soldador",
    TIPO_CLIENTE: "A",
    DIAS_CREDITO: 0,
    FINANC: ""
  },
  {
    codigo: 19586,
    label: "Mudanza",
    TIPO_CLIENTE: "A",
    DIAS_CREDITO: 0,
    FINANC: ""
  }
];

  
  export default tecnicos;
  