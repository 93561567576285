import React, { useState } from 'react';
import { Alert } from "flowbite-react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Spinner } from "flowbite-react";
import AlertRed from '../AlertRed';
import { createContext } from 'react';
import UserContext from '../User';
import { useContext } from 'react';

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
//       {loading ?  <div style={style}><ClipLoader color={"#fb8500"} loading={loading} /></div> : <div></div>}

const FormaAccesoUsuario = (props) => {   
  const user = useContext(UserContext);

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [num, setNum] = useState(Math.floor(Math.random()* (9999 - 1000 + 1)) + 1000);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [timerId, setTimerId] = useState(null);
  const [data, setData] = useState({});
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');


  
  const handleClick = () => {
      console.log('registro de cliente...');
      navigate('/altacliente'); // Change to the desired route
      
  };

    const validateEmail = () => {
      if (!email) {
        setError('Se necesita su email.');
        return false;
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        setError('Formato del email incorrecto.');
        return false;
      }
      setError('');
      return true;
    };

    const validateClave = () => {
      if (!firstName) {
        setError('Se necesita su clave.');
        return false;
      } 
      setError('');
      return true;
    };

    function validateName(name) {
       const NAME_REGEX = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
      return NAME_REGEX.test(name);
    }  


    function startLoading() {
      setLoading(true);
      const id = setInterval(() => {
          if (loading >= 10) {
              clearInterval(id);
              stopLoading();
          }
      }, 500)
      setTimerId(id);
    }
  
    function stopLoading() {
        setLoading(false);
    }

  

    const handleChange = (e) => {
      setEmail(e.target.value);
      setIsValid(validateEmail(e.target.value));
    };

    const handleFirstNameChange = (event) => {
      setFirstName(event.target.value);
      setFirstNameError(validateClave(event.target.value) ? '' : 'Clave invalida.');
    };
  
  
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateEmail()) {
          if (validateClave()) {

            //===============================================
            startLoading();
            setIsActive(true);
    
            try {
              console.log('Data received from user->', data);
              const nombreInput = document.getElementById("nombreid");
              const emailInput = document.getElementById("emailid");
             // const telInput = document.getElementById("telid");
             // const textoInput = document.getElementById("situacionid");
              
    
              const clave = nombreInput.value;
              const email = emailInput.value;
    
    
              console.log("email="+email);
              console.log("clave="+clave);
              console.log("codigo="+num);
             // const tel = telInput.value;
              const tel = '2109130693';
             // const texto = textoInput.value;
              const texto = 'hola';
      
              //dev     http://localhost:8081/api/authenticate
            //en produccion    .post('/api/authenticate',
              const response = await axios
                  .post('/api/authenticate',
                  {
                      headers: {
                          'Content-Type': 'application/json',
                      },
                      "email": email,
                      "passwordHash": clave
                  })
            .then(response => {
              console.log(response);
              console.log(response.data);

              user.nombre=response.data.nombreCompleto;
              user.email=response.data.email;
              user.celular=response.data.numCelular;
              

          
              
              
              setData(response.data);
             

              if (response.status === 404) {
                //Option 1: Display an error message to the user
                console.error('Email y/o clave incorrectos.');
                setError('Email y/o clave incorrectos.');
                //Option 2: Attempt to resolve the conflict
                //Option 3: Refresh data
              }

              if (response.status === 409) {
                //Option 1: Display an error message to the user
                console.error('Conflict: Resource already exists or has conflicting updates.');
                setError('Conflict: Resource already exists or has conflicting updates..');
                //Option 2: Attempt to resolve the conflict
                //Option 3: Refresh data
              }

              if (!loading) navigate('/home'); 
              //if (!loading) navigate('/proveedorchat'); 
    
              //startLoading();
              // if (!loading)     
              // setOpenModal(true);
    
            })
          } catch (err) {
              console.log('Error='+err);
              console.log('Status code='+err.status);
    
              if (err.status === 404) {
                //Option 1: Display an error message to the user
                console.error('Email y/o clave incorrectos.');
                setError('Email y/o clave incorrectos.');
                //Option 2: Attempt to resolve the conflict
                //Option 3: Refresh data
              }

              if (err.status === 409) {
                //Option 1: Display an error message to the user
                console.error('Conflict: Resource already exists or has conflicting updates.');
                setError('Email proporcionado al principio ya esta registrado.');
                //Option 2: Attempt to resolve the conflict
                //Option 3: Refresh data
              }
    
              
          }
          finally {
            setLoading(false);
          }












            
          //ok  if (!loading&&!error) navigate('/altaproveedor2'); // Change to the desired route
          console.log("Ready to go to next screen...");
            //===============================================
            // Send the email 
            console.log('Email sent:', email);
      
          }{
            console.log('Email validation failed.');
          }
  
    
      //  navigate('/altaproveedor2'); // Change to the desired route
        }{
          console.log('Nombre validation failed.');
        }
    };



  
    return (
      <div>
          <section className="relative overflow-hidden bg-white">
      <div className="absolute inset-0">
        <div className='absolute inset-y-0 left-0 w-1/2 bg-yellow-300'></div>
      </div>

      <div className="absolute top-0 left-0 -translate-x-[60%] -translate-y-[75%] z-10">
        <div className="border-[8px] border-white rounded-full w-80 h-80 opacity-20"></div>
      </div>

      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div
          className="relative self-stretch px-4 py-6 overflow-hidde bg-yellow-300 sm:px-6 lg:col-span-2 lg:px-8 lg:py-12">
          <div className="absolute bottom-0 right-0 translate-x-[25%] translate-y-[75%]">
            <div className="border-[8px] border-white rounded-full w-96 h-96 opacity-20 lg:opacity-100"></div>
          </div>

          <div className="relative flex flex-col justify-between h-full max-w-lg mx-auto lg:mr-auto lg:max-w-md">
            <div className="flex-1">
              <h2 className="text-2xl font-semibold tracking-tighter text-white sm:text-5xl xl:text-6xl">
                Bienvenido
              </h2>
              <p className="mt-4 text-base font-normal leading-7 text-blue lg:text-lg lg:mt-6 lg:leading-8">
              Encuentra a alguien de confianza en tu ciudad.
              </p>
            </div>
          </div>
    
        </div>

        <div className="px-4 py-3 bg-white sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto xl:max-w-xl">
            <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              Acceso
            </h2>
            <p className="mt-4 text-base font-normal leading-7 text-gray-600 lg:text-lg lg:mt-6 lg:leading-8">
              Proporcione su email y clave:
            </p>


            <form onSubmit={handleSubmit} action="#" method="POST" className="mt-12 space-y-12 sm:mt-16 lg:mt-20">
              <div className="space-y-4">


          

              <div className="text-left">
                        <label for="" className="text-base font-bold text-gray-900">Email</label>
                            <div className="mt-2.5 relative">
                                <input type="email" name="email" id="emailid" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="" required className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600" />
                            </div>
              </div>

              <div className="text-left">
                        <label for="" className="text-base font-bold text-gray-900">Clave</label>
                            <div className="mt-2.5 relative">
                                <input autoFocus name="fullName" id="nombreid" value={firstName} onChange={handleFirstNameChange} required className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600" />
                                {firstNameError && 
                                  <Alert color="failure">
                                  Favor de escribir su clave
                                  </Alert>}
                            </div>
              </div>







              </div>
         



              
       
              {loading ?  <div className="text-center"><Spinner color="success" aria-label="Extra large spinner example" size="xl" /></div> : <div></div>}

              

              {error && <AlertRed message={error} />}
              <button  onClick={handleSubmit} type="submit" style={{ 
                            backgroundColor: '#fb8500', 
                            color: 'white'
                          }}
    
                className="inline-flex items-center justify-center px-12 py-4 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700">
                Continuar
              </button>
            </form>


      
           

            
            <p className="mt-6 text-sm font-normal text-gray-500">
              No se ha registrado? <a href="#" onClick={handleClick} title=""
                className="text-sm font-semibold text-yellow-600 hover:underline">Registrar</a>
            </p>
                        

          </div>
        </div>
      </div>
    </section>
    </div>
    )
}
export default FormaAccesoUsuario;