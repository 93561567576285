import React from 'react';
import Cta from '../components/Cta';
import Feature2 from '../components/Feature2';
import Precio from '../components/Precio';
import HowItWorks from '../components/HowItWorks';

import TextArea from '../components/TextArea';
import Input from '../components/Input';
import InputEmail from '../components/InputEmail';
import Contacto from '../components/Contacto';
import SuccessModal from '../components/SuccessModal';
import PersonaYSituacion from '../components/PersonaYSituacion';
import Tarjeta from '../components/Tarjeta';

import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
const axios = require("axios");

/*
<div className="px-6 py-12 sm:p-12">
                    <h3 className="text-3xl font-semibold text-center text-gray-900">Detalles</h3>

                    Request Details for ID: {data.id} <br/>
                    Texto={data.texto}<br/>
                    Email={data.email}<br/>
                    Telefono={data.telefono}<br/>
                    Fecha={data.fecha}<br/>
                </div>



                     <PersonaYSituacion 
                email={data.email}
                texto={data.texto}
                telefono={data.telefono}
                fecha={data.fecha}
                seleccionado={data.seleccionado}
            />


*/

function Detalles() {

  const { id } = useParams();
  const location = useLocation();
  const data = location.state;

  const navigate = useNavigate();

  // const data = { texto: "Mi texto", name: "John", age: 30 };

   const handleClickRegresar = () => {
       console.log('regresar...');
      // navigate('/detalles'); // Change to the desired route
    
     //ok   navigate(`/detalles/${props.id}`,{state:{id:props.id}});
       navigate("/lista1");

   };

   const handleClickHacerCita = () => {
    console.log('hacer una cita...');
   // navigate('/detalles'); // Change to the desired route
 
  //ok   navigate(`/detalles/${props.id}`,{state:{id:props.id}});
    navigate("/makeappt");

};

   const todo = {
    id: 10,
    title: 'go to gym',
    body: 'practicing sport is very important',
    userId: 2,
  };


   const handleClickSeleccionar = () => {
    console.log('seleccionar...');
   // navigate('/detalles'); // Change to the desired route
 
  //ok   navigate(`/detalles/${props.id}`,{state:{id:props.id}});
    //navigate("/lista1");
    data.seleccionado=true;
    data.therapistId=3;
    axios
    .put("http://localhost:8080/api/requestor/"+data.id, data)
    .then((response) => {
        console.log(response.status);
        console.log(response.data);
    })
    .catch((e) => console.log('something went wrong :(', e));


};



   

  return (
<div>

<section className="py-2 bg-gray-100 sm:py-2 lg:py-2">
    <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

     

            <div className="mt-6 overflow-hidden bg-white rounded-xl">

            <Tarjeta 
            titulo="Fecha de Solicitud"
            texto={data.fecha}
            />
            <br></br>
            <Tarjeta 
            titulo="Hora de Solicitud"
            texto={data.hora}
            />
            <br></br>


            <Tarjeta 
            titulo="Situación"
            texto={data.texto}
            />
            <br></br>

            <Tarjeta 
            titulo="Email"
            texto={data.email}
            />
            <br></br>

            <Tarjeta 
            titulo="Teléfono"
            texto={"1"+data.telefono}
            />
            <br></br>

       


        
      <br></br>
      <br></br>
      

     
        




<button
                type="button"  onClick={handleClickRegresar} 
                class="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-black transition-all duration-200 bg-white-600 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
            >
                Regresar
            </button>

            &nbsp;&nbsp;&nbsp;&nbsp;
            <button
                type="button"  onClick={handleClickSeleccionar} 
                class="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
            >
                Seleccionar
            </button>


            <button
                type="button"  onClick={handleClickHacerCita} 
                class="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-black transition-all duration-200 bg-white-600 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
            >
                Hacer Cita
            </button>

            &nbsp;&nbsp;&nbsp;&nbsp;

          

            <br></br>
            <br></br>

           

            </div>


           
        
    </div>
</section>



</div>

  );
}

export default Detalles;
