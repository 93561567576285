
import React, { useState } from 'react';

function AlertRed(props){

    return (
        <div class="py-0 bg-white">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div class="max-w-4xl mx-auto">
                <div class="rounded-lg bg-red-50">
                    <div class="p-3">
                        <div class="flex items-start justify-between">
                            <svg class="flex-shrink-0 w-5 h-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                            </svg>
    
                            <div class="flex-1 ml-3">
                                <p class="text-sm font-bold text-red-700">{props.message}</p>
                                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

    )
}
export default AlertRed;