import React, { useState } from 'react';
import { Alert } from "flowbite-react";
import { useNavigate } from 'react-router-dom';
 import UserContext from './User';
import { useContext } from 'react';
import AlertRed from './AlertRed';

const Sig2 = () => {   
    const [isActive, setIsActive] = useState(false);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(true);

    const validateEmail = (email) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return regex.test(email);
    };
  
    const NAME_REGEX = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;

    function validateName(name) {
      return NAME_REGEX.test(name);
    }

    const handleChange = (e) => {
      setEmail(e.target.value);
      setIsValid(validateEmail(e.target.value));
    };

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');

    const handleFirstNameChange = (event) => {
      setFirstName(event.target.value);
      setFirstNameError(validateName(event.target.value) ? '' : 'Invalid first name');
    };
  
    const handleLastNameChange = (event) => {
      setLastName(event.target.value);
      setLastNameError(validateName(event.target.value) ? '' : 'Invalid last name');
    };


    const handlePasswordChange = (event) => {
      console.log('handlePasswordChange...'+event.target.value);
      setPassword(event.target.value);
     
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        setIsActive(false);
      } else {
        setError('');
        // Perform further actions like submitting the form
        setIsActive(true);
      }

    };
  
    const handleConfirmPasswordChange = (event) => {
      console.log('handleConfirmPasswordChange...'+event.target.value);
      setConfirmPassword(event.target.value);

      if (password !== confirmPassword) {
        setError('Passwords do not match');
        setIsActive(false);
      } else {
        setError('');
        // Perform further actions like submitting the form
        setIsActive(true);
      }

      
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
  
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        setIsActive(false);
      } else {
        setError('');
        // Perform further actions like submitting the form
        setIsActive(true);
      }
    };

    const navigate = useNavigate();


    const handleClick = (event) => {
      event.preventDefault();

      console.log('verifica codigo...');
 
      console.log('firstname...'+firstName);
      console.log('codigo en email...'+user.codigoverificacion);
      if (firstName==user.codigoverificacion){
        console.log('verificacion de codigo exitosa...');
        navigate('/altacelular'); // Change to the desired route
        setError('');
      
        
      }else{
        console.log('verificacion de codigo fallo...');
        setError('Codigo incorrecto. Intente de nuevo.');
      }
    };

    const user = useContext(UserContext);

    //user.loggedproveedor=true;


    return (
      <div>
          <section className="relative overflow-hidden bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-yellow-300"></div>
      </div>

      <div className="absolute top-0 left-0 -translate-x-[60%] -translate-y-[75%] z-10">
        <div className="border-[8px] border-white rounded-full w-80 h-80 opacity-20"></div>
      </div>

      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">


        <div className="px-4 py-3 bg-white sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">

          <div className="max-w-lg mx-auto xl:max-w-xl">
            <br></br> <br></br>
            <br></br> <br></br>  <br></br> 
            <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              Verificación de Email
            </h2>
            <p className="mt-4 text-base font-normal leading-7 text-gray-600 lg:text-lg lg:mt-6 lg:leading-8">
              Se le acaba de mandar un email con un código de verificación.
            </p>

            <form onSubmit={handleSubmit} action="#" method="POST" className="mt-12 space-y-12 sm:mt-16 lg:mt-20">
              <div className="space-y-8">
                <div>
                  <label for="fullName" className="text-base font-bold text-gray-900">
                    Escriba el Código de verificación aqui:
                  </label>
                  <div className="mt-2">
                    <input autoFocus type="TEXT" name="fullName" id="fullName" value={firstName} onChange={handleFirstNameChange}
                      className="block px-4 py-4 text-base text-gray-900 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-blue-600 focus:ring-1 focus:ring-blue-600"></input>
                     
                  </div>
                </div>

        



                <div className="flex items-center justify-between">
                  <div className="relative flex items-start">
         
            
                  </div>

         
                </div>
              </div>


              <br></br>
              {error && <AlertRed message={error} />}
              <button type="submit" style={{ 
                            backgroundColor:  '#fb8500',// : '#fb8500', 
                            color: 'white'
                          }}
    
                  onClick={handleClick}
                className="inline-flex items-center justify-center px-12 py-4 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700">
                Verificar email
              </button>
              <br></br> <br></br>       <br></br> <br></br>
            </form>

    

   
          </div>
        </div>
      </div>






  












    </section>
    </div>
    )
}
export default Sig2;