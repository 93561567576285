import React,{ useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from "flowbite-react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
//import tecnicos from './tecnicosToDelete.js';
import areas from './area.js';
import { Card } from "flowbite-react";
import ChatRoom from '../components/ChatRoom';



function Perfil(){

    return (

        <section class=" sm:py-16 lg:py-24py-10 bg-white">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="text-center">
            <img class="object-cover w-32 h-32 mx-auto rounded-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/4/avatar.jpg" alt="" />
            <p class="mt-6 text-lg font-semibold text-black">Juan Perez, <span class="font-normal text-gray-600">Monterrey</span></p>
            <blockquote class="max-w-xl mx-auto mt-7">
                <p class="text-xl leading-relaxed text-black">“Amet minim mollit non deserunt ullam co est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.”</p>
            </blockquote>
        </div>

      
    </div>
<br></br>











</section>

    );
}

export default Perfil;


