import React,{ useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import { useNavigate } from 'react-router-dom';
import { Accordion } from "flowbite-react";

function CtaRegistroDeCliente() {

  const navigate = useNavigate();

  const handleClick = () => {
    console.log('registro de cliente...');
    navigate('/altacliente'); // Change to the desired route
  };

/*
  <section class="py-12 bg-gray-900 sm:py-16 lg:py-20 xl:py-24">
  <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
      <div class="max-w-lg mx-auto text-center">
          <h2 class="text-3xl font-semibold tracking-tight text-white sm:text-4xl lg:text-5xl">Chatee Gratis</h2>
          <p class="mt-4 text-base font-normal leading-7 text-gray-400 lg:text-lg lg:mt-6 lg:leading-8">
            Si desea chatear con esta persona para averiguar mas detalles, solo se tiene que registrar antes. 
            </p>
          <div class="flex flex-col items-center mt-8 space-y-6 md:mt-12">
              <a
                  href="#"
                  title=""
                  class="inline-flex items-center justify-center px-6 py-3 text-base font-medium text-white transition-all duration-200 bg-orange-600 border border-transparent rounded-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                  role="button"
              >
                  Empezar mi registro
              </a>

              <p class="text-sm font-normal text-gray-500">Una vez registrado, podra mandarle un mensaje</p>
          </div>
      </div>
  </div>
</section>

<a href="#" title="" class="text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline">Log in</a>
*/

    return (

   
    

  <section class="py-10 bg-gray-100 sm:py-16 lg:py-24">
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="text-center">
            <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Mándele un mensaje</h2>
            <p class="mt-4 text-2xl font-medium">
              Si desea chatear con esta persona para hacerle una pregunta o más detalles, 
              sólo se tiene que registrar antes. </p>

            <div class="flex flex-col items-center justify-center px-16 mt-8 space-y-4 sm:space-y-0 sm:space-x-4 sm:flex-row lg:mt-12 sm:px-0">
                <a href="#"  onClick={handleClick} title="" class="inline-flex items-center justify-center w-full px-8 py-4 text-base font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md sm:w-auto hover:bg-blue-700 focus:bg-blue-700" role="button"> Empezar mi registro </a>

          
            </div>

            <p class="mt-6 text-base text-black">El registro es gratis </p>
        </div>
    </div>
</section>

    )
}
export default CtaRegistroDeCliente;




