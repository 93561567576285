import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertRed from '../AlertRed';
import UserContext from '../User';
import { useContext } from 'react';


const FormaValidaEmail = (props) => {   
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [codigoEmail, setCodigoEmail] = useState('');
  

  const handleClick = (event) => {
    event.preventDefault();
    console.log('verifica codigo...');
    console.log('codigoEmail='+codigoEmail);
    console.log('codigo enviado a su email...'+user.codigoverificacion);
    if (codigoEmail==user.codigoverificacion){
      console.log('verificacion de codigo exitosa...');
      navigate('/altaclienteclave'); 
      //navigate('/altacelular'); 
      
      setError('');
    }else{
      console.log('verificacion de codigo fallo...');
      setError('Codigo incorrecto. Intente de nuevo.');
    }
  };

  const handleCodigoEmailChange = (event) => {
    setCodigoEmail(event.target.value);
    //setFirstNameError(validateName(event.target.value) ? '' : 'Invalid first name');
  };


  
    return (
      <div>
          <section className="relative overflow-hidden bg-white">
      <div className="absolute inset-0">
        <div className='absolute inset-y-0 left-0 w-1/2 bg-yellow-300'></div>
      </div>

      <div className="absolute top-0 left-0 -translate-x-[60%] -translate-y-[75%] z-10">
        <div className="border-[8px] border-white rounded-full w-80 h-80 opacity-20"></div>
      </div>

      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div
          className="relative self-stretch px-4 py-6 overflow-hidde bg-yellow-300 sm:px-6 lg:col-span-2 lg:px-8 lg:py-12">
          <div className="absolute bottom-0 right-0 translate-x-[25%] translate-y-[75%]">
            <div className="border-[8px] border-white rounded-full w-96 h-96 opacity-20 lg:opacity-100"></div>
          </div>

          <div className="relative flex flex-col justify-between h-full max-w-lg mx-auto lg:mr-auto lg:max-w-md">
            <div className="flex-1">
              <h2 className="text-2xl font-semibold tracking-tighter text-white sm:text-5xl xl:text-6xl">
                

              </h2>
              <p className="mt-4 text-base font-normal leading-7 text-white lg:text-lg lg:mt-6 lg:leading-8">
            

              </p>
            </div>
          </div>
    
        </div>

        <div className="px-4 py-3 bg-white sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto xl:max-w-xl">
            <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Verificación de Email
            </h2>
            <p className="mt-4 text-base font-normal leading-7 text-gray-600 lg:text-lg lg:mt-6 lg:leading-8">
            Se le acaba de mandar un email con un código de verificación
            </p>

    

            <form action="#" method="POST" className="mt-12 space-y-12 sm:mt-16 lg:mt-20">
              <div className="space-y-4">


              <div className="text-left">
                        <label for="" className="text-base font-bold text-gray-900">Escriba el Código de verificación aqui:</label>
                            <div className="mt-2.5 relative">
                                <input autoFocus name="fullName" id="nombreid" value={codigoEmail}  onChange={handleCodigoEmailChange} required className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600" />
                            </div>
              </div>


              </div>
         
              
              {error && <AlertRed message={error} />}
              <button   onClick={handleClick} type="submit" style={{ 
                            backgroundColor: '#fb8500', 
                            color: 'white'
                          }}
    
                className="inline-flex items-center justify-center px-12 py-4 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700">
                Verificar email
              </button>
            </form>

   

          </div>
        </div>
      </div>
    </section>
      </div>
    )
}
export default FormaValidaEmail;